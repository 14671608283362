import React from 'react';
import { useTranslation } from 'react-i18next';
import './CompanyInfo.css';


const CompanyInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="container company-info">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4">{t('company.strategicPlan')}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h3>{t('company.vision')}</h3>
          <ul>
            {t('company.visionList', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <i className="fa fa-eye"></i> {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-6">
          <h3>{t('company.mission')}</h3>
          <ul>
            {t('company.missionList', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <i className="fa fa-bullseye"></i> {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h3>{t('company.goals')}</h3>
          <ul>
            {t('company.goalsList', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <i className="fa fa-check"></i> {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-6">
          <h3>{t('company.values')}</h3>
          <ul>
            {t('company.valuesList', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <i className="fa fa-heart"></i> {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>{t('company.qualityPolicy')}</h3>
          <ul>
            {t('company.qualityPolicyList', { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <i className="fa fa-check-circle"></i> {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
