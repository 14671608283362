import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import CommunicationCard from "../Components/ContactElements/CommunicationCard";
import ContactPageForm1 from "../Components/ContactElements/ContactPageForm1";
import Map from "../Components/ContactElements/Map";
import PageHeader from "../Components/common/PageHeader";
import Banner from "../Containers/Banner";

const ContactPage = () => {
  const { t } = useTranslation(); // Initialize the t function for translations

  const ContactData = [
    {
      id: "1",
      iconClass: "ion-ios-telephone-outline",
      infoBody01: t("Phone No"),
      infoBody02: "+251 911 516 586",
      infoBody03: "+251 116 479 506",
    },
    {
      id: "2",
      iconClass: "fa fa-envelope-o",
      infoBody01: t("Email Address"),
      infoBody02: "bigetabusinessplc@gmail.com",
      infoBody03: "info@bigetaplc.com",
    },
    {
      id: "3",
      iconClass: "fa fa-map-marker",
      infoBody01: t("Office Address"),
      infoBody02: t("Office Address zone"),
      infoBody03: t("Office Address region"),
    },
  ];

  return (
    <>
      <PageHeader
        activeClass01="active"
        activeClass02="d-none"
        routeName01={t("Contact Us")}
        heading={t("Contact Us")}
      />

      <div className="main_wrapper">
        <div className="section">
          <div className="container">
            <div className="gmapbox">
              <div id="googleMap" className="map">
                <Map />
              </div>
            </div>
          </div>
        </div>
        <div className="contact_inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <div className="keepintouch_3">
                  {ContactData.map((data) => (
                    <CommunicationCard key={data.id} data={data} />
                  ))}
                </div>
              </div>
              <div className="col-lg-7 col-md-7 offset-lg-1">
                <div className="contact_us_1">
                  <div className="section_header">
                    <h6 className="section_sub_title">{t("Contact Us")}</h6>
                    <h1 className="section_title">
                      {t("Contact Form Heading")}
                    </h1>
                  </div>
                  <ContactPageForm1 />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Banner
          title={t("Banner Title")}
          heading={t("Banner Heading")}
          phone={t("Banner Phone")}
          bannerType="banner banner_bg_color"
        /> */}
      </div>
    </>
  );
};

export default ContactPage;
