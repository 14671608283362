import React from "react";
import { useTranslation } from "react-i18next";
import AboutBelowContent02 from "../../Components/ExperienceBlocks/AboutBelowContent02";

const ExperienceAbout = () => {
  const { t } = useTranslation();

  const headerData = {
    heading: t("about.slogan"),
    subheading: t("experience.subheading"),
  };

  const ExpericenData = [
    {
      id: "1",
      title: t("about.mission"),
      description: t("about.missionText"),
      img: "images/about/t1.png",
    },
    {
      id: "2",
      title: t("about.vision"),
      description: t("about.visionText"),
      img: "images/about/t2.png",
    },
    {
      id: "3",
      title: t("about.values"),
      description: t("about.valuesText"), // This should be replaced with the actual translation key
      img: "images/about/t3.png",
    },
  ];

  return (
    <div className="experience">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="experience_content about">
              <div className="section_header">
                <h6 className="section_sub_title">{headerData.subheading}</h6>
                <h1 className="section_title">{headerData.heading}</h1>
                <p className="section_desc" style={{marginBottom:"20px"}}>
                  {t("about.companyProfile")}
                </p><p className="section_desc" style={{marginBottom:"20px"}}>
                  {t("about.companyServices")}
                </p><p className="section_desc">
                  {t("about.companyCommitment")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6" style={{marginTop:"auto",marginBottom:"auto"}}>
            <div className="group_image_holder">
              <img src="images/about/about.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceAbout;
